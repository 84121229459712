.products-dropdown-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.products-dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.products-dropdown-exit {
  opacity: 1;
  transform: translateY(0);
}

.products-dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.header-menu {
  position: relative;
}

.header-menu ul li.products-menu {
  position: relative;
}

.header-menu .products-dropdown {
  display: none;
  position: absolute;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 100%;
  left: 0;
  min-width: 150px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
  width: max-content;
}

.header-menu ul li.products-menu.active .products-dropdown {
  display: block;
}


.header-menu .products-dropdown a {
  display: block;
  color: #333;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.header-menu .products-dropdown a:hover {
  background: #f5f5f5;
}

.header-menu .products-dropdown a:last-child {
  border-bottom: none;
}