:root {
    --offset: 6px;
  }
  
.home-hero-text h1 {
    font-size: 55px;
    line-height: 1.06;
    letter-spacing: -0.02em;
    color: #FFF;
    font-weight: bold;
    margin-top: 12px;
  }
  
  .mask {
    height: 55px;
    position: relative;
    overflow: hidden;
    margin-top: var(--offset);
  }
  
  .mask span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 61px;
    padding-bottom: var(--offset);
    
    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
  }
  
  .mask span[data-show] {
    transform: translateY(-100%);
    transition: .5s transform ease-in-out;
  }
  
  .mask span[data-up] {
    transform: translateY(-200%);
    transition: .5s transform ease-in-out;
  }
  
  .mask span:nth-child(1) {
    background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
  }
  
  .mask span:nth-child(2) {
    background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
  }
  
  .mask span:nth-child(3) {
    background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
  }
  
  .mask span:nth-child(4) {
    background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
  }

  .mask span:nth-child(5) {
    background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
  }
  
  .mask span:nth-child(6) {
    background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
  }
  
  .mask span:nth-child(7) {
    background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
  }
  
  .mask span:nth-child(8) {
    background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
  }