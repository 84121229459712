@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -moz-osx-font-smoothings: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #10151C;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body.fixed {
  overflow: hidden;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.app {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

section {
  position: relative;
}

section.section-home-hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 100px 0 0;
}

.hide {
  display: none !important;
}

@media (min-width: 576px) {
  section.section-home-hero {
    padding: 150px 0 0;
  }
}
@media (min-width: 1200px) {
  section.section-home-hero {
    height: 100vh;
    min-height: 800px;
    padding: 94px 0 0;
  }
}
section.section-home-percentage {
  padding: 100px 0 0;
}

@media (min-width: 576px) {
  section.section-home-percentage {
    padding: 150px 0 0;
  }
}
/*@media (min-width: 992px) {
  section.section-home-percentage {
    padding: 100px 0 150px;
  }
}*/
section.section-home-power {
  padding: 100px 0 0;
}

@media (min-width: 576px) {
  section.section-home-power {
    padding: 150px 0 0;
  }
}
@media (min-width: 992px) {
  section.section-home-power {
    padding: 0px;
  }
}
section.section-home-power .section-title p {
  max-width: 926px;
  margin: 20px auto 0;
}

section.section-home-data {
  padding: 100px 0 0;
}

@media (min-width: 576px) {
  section.section-home-data {
    padding: 150px 0 0;
  }
}
@media (min-width: 1200px) {
  section.section-home-data {
    padding: 280px 0;
  }
}
section.section-home-story {
  padding: 100px 0;
}

@media (min-width: 576px) {
  section.section-home-story {
    padding: 120px 0;
  }
}
section.section-home-story .section-title p {
  max-width: 612px;
  margin: 20px auto 0;
}

section.section-mail {
  padding: 75px 0 25px;
}

@media (min-width: 576px) {
  section.section-mail {
    padding: 100px 0 150px;
  }
}
@media (min-width: 992px) {
  section.section-mail {
    min-height: 100vh;
    padding: 150px 0;
  }
}
section.section-thanks {
  padding: 75px 0 25px;
}

@media (min-width: 576px) {
  section.section-thanks {
    padding: 100px 0 150px;
  }
}
@media (min-width: 992px) {
  section.section-thanks {
    min-height: 100vh;
    padding: 150px 0;
  }
}
section.section-pricing {
  padding: 160px 0 60px;
}

section.section-pricing .section-title p {
  max-width: 612px;
  margin: 20px auto 0;
}

section.section-faq {
  padding: 60px 0;
  background-image: -webkit-gradient(linear, right top, left top, from(#1B2430), color-stop(51.56%, #151C24), to(#1B2430));
  background-image: -o-linear-gradient(right, #1B2430 0%, #151C24 51.56%, #1B2430 100%);
  background-image: linear-gradient(270deg, #1B2430 0%, #151C24 51.56%, #1B2430 100%);
}

.section-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.section-bg-story {
  width: 100%;
  overflow: hidden;
  background-color: #10151C;
}

.section-bg-data {
  width: 100%;
  overflow: hidden;
  background-color: #10151C;
}

.section-bg-pricing {
  width: 100%;
  overflow: hidden;
}

.section-title h2 {
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .section-title h2 {
    font-size: 42px;
  }
}
.section-title h2 span {
  font-weight: 700;
}

.text-gradient-colorful {
  font-weight: 700;
  background-image: -webkit-gradient(linear, right top, left top, color-stop(18.85%, #FF0A71), color-stop(59.42%, #AD3DF9), color-stop(96.2%, #319CFF));
  background-image: -o-linear-gradient(right, #FF0A71 18.85%, #AD3DF9 59.42%, #319CFF 96.2%);
  background-image: linear-gradient(270deg, #FF0A71 18.85%, #AD3DF9 59.42%, #319CFF 96.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.section-title h2 + p {
  margin: 20px 0 0;
}

.section-title p {
  margin: 0;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.section-title p strong {
  font-weight: 700;
}

.bg-top-bg {
  position: absolute;
  z-index: 1;
  bottom: -120px;
  left: calc(50% + 104px);
}

@media (min-width: 992px) {
  .bg-top-bg {
    top: -104px;
    bottom: auto;
    left: 80px;
  }
}
.bg-top-bg img {
  display: block;
  width: 207px;
}

.bg-right-text {
  display: none;
  position: absolute;
  z-index: 1;
  bottom: 68px;
  right: 24px;
}

@media (min-width: 992px) {
  .bg-right-text {
    display: block;
  }
}
.bg-right-text img {
  display: block;
  width: 8px;
}

.bg-bottom-bg {
  display: none;
  position: absolute;
  z-index: 1;
  top: 580px;
  left: calc(50% + 200px);
}

@media (min-width: 992px) {
  .bg-bottom-bg {
    display: block;
  }
}
.bg-bottom-bg img {
  display: block;
  width: 239px;
}

.bg-left-text {
  display: none;
  position: absolute;
  z-index: 1;
  bottom: 68px;
  left: 24px;
}

@media (min-width: 992px) {
  .bg-left-text {
    display: block;
  }
}
.bg-left-text img {
  display: block;
  width: 13px;
}

.bg-home-main {
  display: none;
  position: absolute;
  z-index: 0;
  top: -40px;
  left: calc(50% + 160px);
}

@media (min-width: 992px) {
  .bg-home-main {
    display: block;
  }
}
.bg-home-main img {
  display: block;
  height: 980px;
}

.bg-story {
  background-color: #10151C;
}

.bg-story-circle-blue {
  position: absolute;
  z-index: 1;
  top: 99px;
  left: calc(50% - 842px);
  width: 719px;
  height: 719px;
  background-color: #1F4AB5;
  -webkit-filter: blur(125px);
  filter: blur(125px);
  border-radius: 50%;
  mix-blend-mode: lighten;
}

.bg-story-circle-violet {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: calc(50% - 145px);
  width: 583px;
  height: 583px;
  background-color: #A048FA;
  -webkit-filter: blur(125px);
  filter: blur(125px);
  border-radius: 50%;
  mix-blend-mode: lighten;
}

.bg-story-circle-pink {
  position: absolute;
  z-index: 3;
  top: 331px;
  left: calc(50% + 192px);
  width: 1191px;
  height: 1191px;
  background-color: #F50369;
  -webkit-filter: blur(125px);
  filter: blur(125px);
  border-radius: 50%;
  mix-blend-mode: lighten;
}

.bg-story-image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2766px;
  height: 100%;
}

.bg-story-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #10151C;
}

.bg-story-pattern {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.bg-story-pattern::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/bg-grain.png');
  background-size: 1440px 900px;
  background-repeat: repeat;
}

.bg-data {
  background-color: #10151C;
}

.bg-data-image {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 680px;
}

@media (min-width: 768px) {
  .bg-data-image {
    height: 900px;
  }
}
@media (min-width: 1200px) {
  .bg-data-image {
    top: 0;
    bottom: auto;
    height: 100%;
  }
}
.bg-data-image img {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 680px;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #10151C;
}

@media (min-width: 768px) {
  .bg-data-image img {
    height: 900px;
  }
}
@media (min-width: 1200px) {
  .bg-data-image img {
    height: 100%;
  }
}
.bg-data-image img:nth-child(1) {
  display: none;
}

@media (min-width: 1200px) {
  .bg-data-image img:nth-child(1) {
    display: block;
  }
}
.bg-data-image img:nth-child(2) {
  display: block;
}

@media (min-width: 1200px) {
  .bg-data-image img:nth-child(2) {
    display: none;
  }
}
.bg-data-image:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -o-linear-gradient(top, #10151C, transparent calc(0% + 100px), transparent calc(100% - 75px), #10151C 100%);
  background-image: linear-gradient(to bottom, #10151C, transparent calc(0% + 100px), transparent calc(100% - 75px), #10151C 100%);
}

.bg-data-circle-blue {
  position: absolute;
  z-index: 1;
  top: calc(100% - 608px);
  left: calc(50% - 270px);
  width: 540px;
  height: 540px;
  background-color: #1F4AB5;
  -webkit-box-shadow: 0px 4px 135px #1F4AB5;
  box-shadow: 0px 4px 135px #1F4AB5;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 50%;
}

@media (min-width: 768px) {
  .bg-data-circle-blue {
    top: calc(100% - 777px);
    left: calc(50% - 354px);
    width: 709px;
    height: 709px;
  }
}
@media (min-width: 1200px) {
  .bg-data-circle-blue {
    top: 120px;
    left: calc(50% + 59px);
  }
}
.bg-data-circle-pink {
  position: absolute;
  z-index: 2;
  top: calc(100% - 358px);
  left: calc(50% - 64px);
  width: 248px;
  height: 248px;
  background-color: #F50369;
  -webkit-box-shadow: 0px 0px 124px rgba(245, 3, 105, 0.7);
  box-shadow: 0px 0px 124px rgba(245, 3, 105, 0.7);
  border-radius: 50%;
}

@media (min-width: 768px) {
  .bg-data-circle-pink {
    top: calc(100% - 473px);
    left: calc(50% - 84px);
    width: 327px;
    height: 327px;
  }
}
@media (min-width: 1200px) {
  .bg-data-circle-pink {
    top: 424px;
    left: calc(50% + 329px);
  }
}
.bg-data-pattern {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.bg-data-pattern::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/bg-grain.png');
  background-size: 1440px 900px;
  background-repeat: repeat;
}

.bg-pricing-image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}

.bg-pricing-image img {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #10151C;
}

.main-content {
  position: relative;
  z-index: 0;
}

.main-container {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
}

.text-center {
  text-align: center;
}

.text-pink {
  color: #F50369;
}

.accordion.active .accordion-head h3 {
  color: #F50369;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.accordion.active .accordion-head img {
  -webkit-transform: rotateZ(180deg);
  -ms-transform: rotate(180deg);
  transform: rotateZ(180deg);
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.accordion.active .accordion-body {
  display: block;
}

.accordion-head {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 20px;
  padding: 25px;
}

.accordion-head h3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 40px);
  flex: 0 0 calc(100% - 40px);
  width: calc(100% - 40px);
  margin: 0;
  font-weight: 700;
  line-height: 150%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

@media (min-width: 768px) {
  .accordion-head h3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 48px);
    flex: 0 0 calc(100% - 48px);
    width: calc(100% - 48px);
  }
}
.accordion-head img {
  width: 20px;
  -webkit-transform: rotateZ(0deg);
  -ms-transform: rotate(0deg);
  transform: rotateZ(0deg);
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

@media (min-width: 768px) {
  .accordion-head img {
    width: 28px;
  }
}
.accordion-body {
  display: none;
  padding: 0 25px 25px;
}

.tab-head {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
}

.tab-head.home-power-tab-head {
  padding: 0 0 15px;
  border-bottom: 1px solid #FFFFFF;
}

.tab-head.home-power-tab-head .tab-nav + .tab-nav {
  margin: 0 0 0 20px;
}

.tab-head.home-power-tab-head .tab-nav.active .nav-icon {
  background-color: #202d3c;
}

.tab-head.home-power-tab-head .tab-nav.active p {
  color: #FFFFFF;
}

.tab-nav {
  cursor: pointer;
}

.tab-nav svg {
  opacity: 0.3;
}

.tab-nav.active svg {
  opacity: 1;
}

.tab-body {
  position: relative;
}

.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}

.cross-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 9px;
}

.cross-list-header {
  font-weight: bold;
  color: #FFF;
}

.cross-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-gap: 10px;
}

.cross-list-item img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12px;
  flex: 0 0 12px;
  width: 12px;
  margin: 12px 0 0;
}

.cross-list-item p {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 22px);
  flex: 0 0 calc(100% - 22px);
  width: calc(100% - 22px);
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #E8E9EB;
}

.checked-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.checked-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-gap: 10px;
}

.checked-list-item img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16px;
  flex: 0 0 16px;
  width: 16px;
  margin: 12px 0 0;
}

.checked-list-item p {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 26px);
  flex: 0 0 calc(100% - 26px);
  width: calc(100% - 26px);
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #E8E9EB;
}

.hubspot-embed-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 20px;
}

.hubspot-embed-form form .hs_error_rollup {
  display: none;
}

.hubspot-embed-form form div:nth-child(1) {
  position: relative;
  width: 400px;
}

.hubspot-embed-form form div > label {
  display: none;
}

.hubspot-embed-form form div input[type=email] {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: transparent;
  border: 2px solid #2763ff;
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.hubspot-embed-form form div ul {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.hubspot-embed-form form div ul li label {
  font-size: 16px;
  color: #FFFFFF;
}

.hubspot-embed-form form div .hs-button {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #2763ff;
  border: 2px solid #2763ff;
  border-radius: 8px;
}

.hubspot-embed-form form div .hs-button:hover, .hubspot-embed-form form div .hs-button:focus, .hubspot-embed-form form div .hs-button:active {
  background-color: #2763ff;
  border: 2px solid #2763ff;
  -webkit-box-shadow: 0px 0px 24px #466fd8;
  box-shadow: 0px 0px 24px #466fd8;
}

.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.btn:hover, .btn:focus, .btn:active {
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-full {
  width: 100%;
}

.btn-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 12px;
}

.btn-primary {
  width: 100%;
  padding: 16px 0;
  font-size: 16px;
  line-height: 125%;
  color: #FFFFFF;
  background-color: #2663FF;
  -webkit-box-shadow: 0px 0px 2.41919px rgba(0, 0, 0, 0.084), 0px 2.41919px 2.41919px rgba(0, 0, 0, 0.168);
  box-shadow: 0px 0px 2.41919px rgba(0, 0, 0, 0.084), 0px 2.41919px 2.41919px rgba(0, 0, 0, 0.168);
  border-radius: 5px;
}

@media (min-width: 576px) {
  .btn-primary {
    max-width: 320px;
    padding: 20px 0;
    font-size: 20px;
  }
}
.btn-blue {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #1F4AB5;
  border: 2px solid #1F4AB5;
  border-radius: 8px;
}

.btn-blue:hover, .btn-blue:focus, .btn-blue:active {
  background-color: #355CBC;
  border: 2px solid #355CBC;
  -webkit-box-shadow: 0px 0px 24px #1F4AB5;
  box-shadow: 0px 0px 24px #1F4AB5;
}

.btn-violet {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #A048FA;
  border: 2px solid #A048FA;
  border-radius: 8px;
}

.btn-violet:hover, .btn-violet:focus, .btn-violet:active {
  background-color: #A95AFA;
  border: 2px solid #A95AFA;
  -webkit-box-shadow: 0px 0px 24px #A048FA;
  box-shadow: 0px 0px 24px #A048FA;
}

.btn-pink {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #F50369;
  border: 2px solid #F50369;
  border-radius: 8px;
}

.btn-pink:hover, .btn-pink:focus, .btn-pink:active {
  background-color: #F61C78;
  border: 2px solid #F61C78;
  -webkit-box-shadow: 0px 0px 24px #F50369;
  box-shadow: 0px 0px 24px #F50369;
}

.btn-outline-white {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
}

.btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active {
  color: #10151C;
  background-color: #FFFFFF;
  border: 2px solid #FFFFFF;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.btn-outline-blue {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #2763FF;
  border: 2px solid #2763FF;
  border-radius: 8px;
}

.btn-outline-blue:hover, .btn-outline-blue:focus, .btn-outline-blue:active {
  color: #FFFFFF;
  background-color: #1F4AB5;
  border: 2px solid #1F4AB5;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.btn-outline-violet {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #A048FA;
  border: 2px solid #A048FA;
  border-radius: 8px;
}

.btn-outline-violet:hover, .btn-outline-violet:focus, .btn-outline-violet:active {
  color: #FFFFFF;
  background-color: #A048FA;
  border: 2px solid #A048FA;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.btn-outline-pink {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #F50369;
  border: 2px solid #F50369;
  border-radius: 8px;
}

.btn-outline-pink:hover, .btn-outline-pink:focus, .btn-outline-pink:active {
  color: #FFFFFF;
  background-color: #F50369;
  border: 2px solid #F50369;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.btn-cta {
  width: 100%;
  padding: 16px 0;
  font-size: 16px;
  line-height: 125%;
  color: #FFFFFF;
  background-color: #2663FF;
  -webkit-box-shadow: 0px 0px 2.41919px rgba(0, 0, 0, 0.084), 0px 2.41919px 2.41919px rgba(0, 0, 0, 0.168);
  box-shadow: 0px 0px 2.41919px rgba(0, 0, 0, 0.084), 0px 2.41919px 2.41919px rgba(0, 0, 0, 0.168);
  border-radius: 5px;
}

@media (min-width: 576px) {
  .btn-cta {
    max-width: 320px;
    padding: 20px 0;
    font-size: 20px;
  }
}
.btn-submit {
  padding: 16px;
  font-size: 16px;
  line-height: 125%;
  color: #FFFFFF;
  background-color: #2663FF;
  border-radius: 100px;
}

.btn-story {
  padding: 8px 0 10px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
}

.btn-try {
  width: 100%;
  max-width: 636px;
  padding: 11px 27px;
}

.btn-pricing {
  padding: 12px;
}

.form-control {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  border: 0;
  background-color: transparent;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:focus, .form-control:active {
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-email {
  padding: 0 40px;
}

::-webkit-input-placeholder {
  color: #929292;
}

:-ms-input-placeholder {
  color: #929292;
}

::-moz-placeholder {
  color: #929292;
}

::-ms-input-placeholder {
  color: #929292;
}

::placeholder {
  color: #929292;
}

header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
}

.header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 0 0;
}

@media (min-width: 576px) {
  .header-wrapper {
    padding: 50px 0 0;
  }
}
.header-logo a {
  display: block;
}

.header-logo a img {
  display: block;
  max-height: 24px;
}

.header-menu-wrapper {
  position: fixed;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 80px 0;
  background-color: rgb(0 0 0 / 90%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.header-menu-wrapper.open {
  top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

@media (min-width: 576px) {
  .header-menu-wrapper.open {
    top: initial;
    opacity: initial;
    visibility: initial;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
@media (min-width: 576px) {
  .header-menu-wrapper {
    position: initial;
    top: initial;
    left: initial;
    width: auto;
    height: auto;
    padding: 0;
    background-color: transparent;
    opacity: 1;
    visibility: visible;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.header-menu-wrapper .close-mobile {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 40px;
  right: 20px;
  width: 24px;
  height: 24px;
}

@media (min-width: 576px) {
  .header-menu-wrapper .close-mobile {
    display: none;
  }
}
.header-menu-wrapper .close-mobile span {
  position: absolute;
  display: block;
  height: 3px;
  background-color: #10151C;
  border-radius: 2px;
}

.header-menu-wrapper .close-mobile span:nth-child(1) {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateZ(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotateZ(45deg);
  width: 20px;
}

.header-menu-wrapper .close-mobile span:nth-child(2) {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateZ(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotateZ(-45deg);
  width: 20px;
}

.header-menu .menu-mobile {
  cursor: pointer;
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
}

@media (min-width: 576px) {
  .header-menu .menu-mobile {
    display: none;
  }
}
.header-menu .menu-mobile span {
  position: absolute;
  display: block;
  height: 3px;
  background-color: #FFFFFF;
  border-radius: 2px;
}

.header-menu .menu-mobile span:nth-child(1) {
  top: calc(50% - 6px);
  left: 2px;
  width: 20px;
}

.header-menu .menu-mobile span:nth-child(2) {
  top: calc(50% + 3px);
  left: 6px;
  width: 16px;
}

.header-menu nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 26px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

header ul li.active a {
  position: relative;
}

header ul li a:hover {
  opacity: 0.5;
  box-shadow: rgba(0, 0, 0, 0.084);
}

header nav ul li.active a:after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #F26D78;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgb(245 1 105 / 58%) 0px 4px 20px 5px;
}

@media (min-width: 576px) {
  .header-menu nav ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.header-menu nav ul li a:hover {
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.header-menu nav ul li a.btn.btn-outline-pink {
  color: #F50369;
}

.header-menu nav ul li a.btn.btn-outline-pink:hover, .header-menu nav ul li a.btn.btn-outline-pink:focus, .header-menu nav ul li a.btn.btn-outline-pink:active {
  color: #FFFFFF;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

footer {
  padding: 60px 0 44px;
  background-color: #000000;
  z-index: 1;
}

.footer-wrapper {
  position: relative;
}

.footer-logo a {
  display: block;
}

.footer-logo a img {
  display: block;
  max-height: 24px;
}

.footer-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-gap: 40px;
  margin: 68px 0 0;
}

@media (min-width: 768px) {
  .footer-menu {
    grid-gap: 20px;
  }
}
.footer-menu-group {
  width: calc((100% - 40px) / 2);
}

@media (min-width: 768px) {
  .footer-menu-group {
    width: calc((100% - 60px) / 4);
  }
}
.footer-menu-group h3 {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.footer-menu-group ul {
  margin: 18px 0 0;
  padding: 0;
  list-style-type: none;
}

.footer-menu-group ul li a {
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.footer-menu-group ul li a:hover {
  color: #F50369;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.footer-copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-gap: 25px;
  margin: 68px 0 0;
}

@media (min-width: 576px) {
  .footer-copy {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.footer-copy p {
  margin: 0;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.footer-copy p strong {
  font-weight: 600;
}

.footer-copy ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 25px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-copy ul li a {
  display: block;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.footer-copy ul li a:hover {
  color: #F50369;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.home-hero-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (min-width: 1200px) {
  .home-hero-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.home-hero-text {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
  margin: 0;
}

@media (min-width: 576px) {
  .home-hero-text {
    margin: 60px 0 0;
  }
}
@media (min-width: 1200px) {
  .home-hero-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 680px;
    flex: 0 0 680px;
    width: 680px;
    margin: 0;
    /*top: -120px;*/
  }
}
.home-hero-text > img {
  display: none;
  width: 100%;
  max-width: 98px;
  margin: 0;
}

@media (min-width: 768px) {
  .home-hero-text > img {
    display: block;
  }
}
.home-hero-text h1 {
  max-width: 512px;
  margin: 0 auto;
  font-size: 28px;
  line-height: 145%;
  letter-spacing: 0.02em;
  text-align: center;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-hero-text h1 {
    font-size: 36px;
  }
}
@media (min-width: 1200px) {
  .home-hero-text h1 {
    margin: 0;
    text-align: left;
    line-height: 110%;
  }
}

.home-hero-text h1 + h2 {
  margin: 24px auto 0;
}

@media (min-width: 1200px) {
  .home-hero-text h1 + h2 {
    margin: 48px 0 0;
  }
}
.home-hero-text h2 {
  max-width: 342px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

@media (min-width: 1200px) {
  .home-hero-text h2 {
    margin: 0;
    text-align: left;
  }
}
.home-hero-text h2 + p {
  margin: 24px auto 0;
}

@media (min-width: 1200px) {
  .home-hero-text h2 + p {
    margin: 48px 0 0;
  }
}
.home-hero-text h3 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: #AC8D3E;
}

@media (min-width: 576px) {
  .home-hero-text h3 {
    font-size: 32px;
  }
}
@media (min-width: 768px) {
  .home-hero-text h3 {
    margin: 40px 0 0;
  }
}
.home-hero-text p {
  max-width: 500px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFF;
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .home-hero-text p {
    text-align: left;
    margin: 0;
    margin: 90px 0;
  }
}
.home-hero-text-step {
  margin: 20px 0 0;
}

@media (min-width: 576px) {
  .home-hero-text-step {
    margin: 40px 0 0;
  }
}
.home-hero-text-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (min-width: 576px) {
  .home-hero-text-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.home-hero-text-item + .home-text-item {
  margin: 20px 0 0;
}

@media (min-width: 576px) {
  .home-hero-text-item + .home-text-item {
    margin: 40px 0 0;
  }
}
.home-hero-text-item h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-hero-text-item h4 {
    font-size: 24px;
  }
}
.home-hero-text-item span {
  display: block;
  margin: 5px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  color: #929292;
}

@media (min-width: 576px) {
  .home-hero-text-item span {
    font-size: 20px;
  }
}
.home-hero-text-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  background-color: #2663FF;
  border-radius: 50%;
}

@media (min-width: 576px) {
  .home-hero-text-number {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
  }
}
.home-hero-text-number p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-hero-text-number p {
    font-size: 25px;
    line-height: 1.25;
  }
}
.home-hero-text-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 30px);
  flex: 0 0 calc(100% - 30px);
  width: calc(100% - 30px);
  padding: 0 0 0 15px;
}

@media (min-width: 576px) {
  .home-hero-text-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 50px);
    flex: 0 0 calc(100% - 50px);
    width: calc(100% - 50px);
  }
}
.home-hero-text-form {
  margin: 32px 0 0;
}

@media (min-width: 576px) {
  .home-hero-text-form {
    margin: 48px 0 0;
  }
}
.home-hero-text-form .form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 10px;
}

@media (min-width: 576px) {
  .home-hero-text-form .form-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    grid-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .home-hero-text-form .form-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.home-hero-text-form .form-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
  border: 2px solid #2763FF;
  border-radius: 8px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

button.form-text-image {
  background: transparent;
  border: none;
  cursor: pointer;
}

@media (min-width: 576px) {
  .home-hero-text-form .form-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    width: 400px;
  }
}
.home-hero-text-form .form-text:hover {
  border-color: #1F4AB5;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.home-hero-text-form .form-text:hover .form-text-image {
  background-color: #1F4AB5;
  border-color: #1F4AB5;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.home-hero-text-form .form-text-image {
  padding: 15px;
  border-right: 2px solid #2763FF;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.home-hero-text-form .form-text-image img {
  display: block;
}

.home-hero-text-form .form-text input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.home-hero-text-form .form-text ::-webkit-input-placeholder {
  color: #FFFFFF;
}

.home-hero-text-form .form-text :-ms-input-placeholder {
  color: #FFFFFF;
}

.home-hero-text-form .form-text ::-moz-placeholder {
  color: #FFFFFF;
}

.home-hero-text-form .form-text ::-ms-input-placeholder {
  color: #FFFFFF;
}

.home-hero-text-form .form-text ::placeholder {
  color: #FFFFFF;
}

.home-hero-text-form .form-button {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .home-hero-text-form .form-button {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 152px;
    flex: 0 0 152px;
    width: 152px;
  }
}
.home-hero-form {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .home-hero-form {
    padding: 120px 0 0 0;
  }
}
@media (min-width: 1200px) {
  .home-hero-form {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 680px);
    flex: 0 0 calc(100% - 680px);
    width: calc(100% - 680px);
    padding: 0 0 0 120px;
  }
}
.home-hero-form img {
  display: block;
  max-width: 200px;
}

@media (min-width: 576px) {
  .home-hero-form img {
    max-width: 320px;
  }
}
.home-hero-form h2 {
  margin: 15px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-hero-form h2 {
    margin: 20px 0 0;
    font-size: 24px;
  }
}
.home-hero-image {
  position: relative;
  z-index: 0;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
  height: 560px;
}

@media (min-width: 576px) {
  .home-hero-image {
    height: 680px;
  }
}
@media (min-width: 1200px) {
  .home-hero-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 680px);
    flex: 0 0 calc(100% - 680px);
    width: calc(100% - 680px);
  }
}
.home-hero-image > img {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 720px;
  background-color: #10151C;
}

@media (min-width: 768px) {
  .home-hero-image > img {
    width: 1080px;
  }
}
.home-hero-image-panel {
  position: absolute;
  z-index: 2;
  top: calc(50% - 240px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 12px;
}

@media (min-width: 768px) {
  .home-hero-image-panel {
    width: 294px;
    grid-gap: 17px;
    top: calc(50% - 320px);
  }
}
.home-hero-image-panel-item {
  position: relative;
  top: -20px;
  padding: 8px 8px 8px 32px;
  background-color: rgba(255, 255, 255, 0.35);
  -webkit-box-shadow: 0px 4px 24px rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 24px rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 3px;
  opacity: 0;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.home-hero-image-panel-item.animation-stopped {
  top: 0;
  -webkit-animation-name: none;
  animation-name: none;
  opacity: 1;
}

@media (min-width: 768px) {
  .home-hero-image-panel-item {
    padding: 10px 10px 10px 40px;
    border-radius: 5px;
  }
}
.home-hero-image-panel-item:nth-child(1) {
  -webkit-animation-name: homeHeroPanel1;
  animation-name: homeHeroPanel1;
}

.home-hero-image-panel-item:nth-child(2) {
  -webkit-animation-name: homeHeroPanel2;
  animation-name: homeHeroPanel2;
}

.home-hero-image-panel-item:nth-child(3) {
  -webkit-animation-name: homeHeroPanel3;
  animation-name: homeHeroPanel3;
}

.home-hero-image-panel-item:nth-child(4) {
  -webkit-animation-name: homeHeroPanel4;
  animation-name: homeHeroPanel4;
}

.home-hero-image-panel-item img {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 15px;
}

@media (min-width: 768px) {
  .home-hero-image-panel-item img {
    height: 18px;
  }
}
.home-hero-image-panel-item p {
  margin: 0;
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .home-hero-image-panel-item p {
    font-size: 12px;
  }
}
.home-hero-image.empty img {
  background-color: transparent;
}

.home-hero-bg {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 420px;
  height: 420px;
  background-color: #10151C;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  -webkit-box-shadow: 0px 4px 74px 7px rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 74px 7px rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .home-hero-bg {
    width: 628px;
    height: 628px;
  }
}
.home-hero-bg-circle {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 470px;
  height: 470px;
  background-color: #000000;
  -webkit-filter: blur(22px);
  filter: blur(22px);
}

.home-hero-bg-pattern {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: multiply;
}

.home-hero-bg-pattern::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/bg-grain.png');
  background-size: 1440px 900px;
  background-repeat: repeat;
  border-radius: 50%;
}

.home-percentage-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (min-width: 992px) {
  .home-percentage-wrapper {
    -ms-flex-wrap: no-wrap;
    flex-wrap: no-wrap;
  }
}
.home-percentage-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
  text-align: center;
}

@media (min-width: 992px) {
  .home-percentage-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 480px;
    flex: 0 0 480px;
    width: 480px;
  }
}
.home-percentage-left-bg {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 640px;
}

.home-percentage-left-bg img {
  width: 100%;
}

.home-percentage-left > h2 {
  position: relative;
  z-index: 1;
  margin: 0;
  font-weight: 200;
  font-size: 100px;
  line-height: 88%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-percentage-left > h2 {
    font-size: 200px;
  }
}
.home-percentage-left > p {
  position: relative;
  z-index: 1;
  max-width: 400px;
  margin: 30px auto 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #BABEC7;
}

.home-percentage-left > p + .btn {
  margin: 30px 0 0;
}

.home-percentage-left > .btn {
  position: relative;
  z-index: 1;
}

.home-percentage-right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
  margin: 60px 0 0;
}

@media (min-width: 992px) {
  .home-percentage-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 480px);
    flex: 0 0 calc(100% - 480px);
    width: calc(100% - 480px);
    max-width: 560px;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .home-percentage-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 560px;
    flex: 0 0 560px;
    width: 560px;
  }
}
.home-percentage-right > h2 {
  max-width: 576px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-align: center;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-percentage-right > h2 {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .home-percentage-right > h2 {
    max-width: 100%;
    margin: 0;
    text-align: left;
  }
}
.home-percentage-right > h2 + p {
  margin: 34px auto 0;
}

@media (min-width: 992px) {
  .home-percentage-right > h2 + p {
    margin: 68px 0 0;
  }
}
.home-percentage-right > p {
  max-width: 426px;
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-align: center;
  color: #E8E9EB;
}

@media (min-width: 992px) {
  .home-percentage-right > p {
    text-align: left;
  }
}
.home-percentage-right .cross-list {
  margin: 28px 0 0;
}

@media (min-width: 576px) {
  .home-percentage-right .cross-list {
    margin: 56px 0 0;
  }
}
.home-percentage-right .cross-list-item {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .home-percentage-right .cross-list-item {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.home-percentage-right .cross-list-item p {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

@media (min-width: 992px) {
  .home-percentage-right .cross-list-item p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 22px);
    flex: 0 0 calc(100% - 22px);
    width: calc(100% - 22px);
  }
}
.home-percentage-sadface {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 20px;
  margin: 34px 0 0;
}

.home-percentage-sadface-filled {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 10px;
}

.home-percentage-sadface-empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 10px;
}

.home-power-tab {
  margin: 44px 0 0;
}

.home-power-tab-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin: 30px 0 0;
  border-radius: 8px;
  overflow: hidden;
}

@media (min-width: 992px) {
  .home-power-tab-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .home-power-tab-wrapper {
    grid-template-columns: 510px auto;
  }
}
.home-power-tab-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-power-tab-nav .nav-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  /*background-color: rgba(255, 255, 255, 0.3);*/
  border-radius: 5px;
  position: relative;
  color: #fff;
}

.nav-icon svg {
  font-size: 20px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  top: calc(50% - 10px);
}

.home-power-tab-nav p {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 0 0 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.3);
}

.home-power-tab-text {
  padding: 30px 25px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #171E28), to(#141A23));
  background-image: -o-linear-gradient(top, #171E28 30%, #141A23);
  background-image: linear-gradient(to bottom, #171E28 30%, #141A23);
}

@media (min-width: 768px) {
  .home-power-tab-text {
    padding: 40px 30px;
  }
}
.home-power-tab-text > h3 {
  margin: 0;
  font-weight: 700;
  font-size: 25px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.home-power-tab-text > h3 + p {
  margin: 20px 0 0;
}

.home-power-tab-text > p {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #E8E9EB;
}

.home-power-tab-text > p + h4 {
  margin: 20px 0 0;
}

.home-power-tab-text > h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.home-power-tab-text > h4 + .checked-list {
  margin: 10px 0 0;
}

.home-power-tab-text > .btn {
  margin: 20px 0 0;
}

.home-power-tab-media {
  padding: 30px 25px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #171E28), color-stop(130%, #263446));
  background-image: -o-linear-gradient(top, #171E28 30%, #263446 130%);
  background-image: linear-gradient(to bottom, #171E28 30%, #263446 130%);
}

@media (min-width: 768px) {
  .home-power-tab-media {
    padding: 40px 30px;
  }
}
.home-power-media-video {
  width: 100%;
  /*height: 320px;
  border: 1px solid #FFFFFF;*/
  border-radius: 8px;
}

.home-power-media-video iframe {
  width: 100%;
  height: 320px;
  border: 0;
  border-radius: 8px;
}

.home-power-media-box {
  margin: 30px 0 0;
  padding: 20px;
  background-color: #12171F;
  border-radius: 8px;
}

.home-power-media-box h3 {
  margin: 0;
  color: #FFFFFF;
}

.home-power-media-box h3 + p {
  margin: 10px 0 0;
}

.home-power-media-box p {
  margin: 0;
  color: #FFFFFF;
}

.home-data-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home-data-text {
  width: 100%;
}

@media (min-width: 1200px) {
  .home-data-text {
    width: 526px;
  }
}
.home-data-text h2 {
  max-width: 526px;
  margin: 0 auto;
  font-weight: bold;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-align: center;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .home-data-text h2 {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .home-data-text h2 {
    max-width: 100%;
    margin: 0;
    text-align: left;
  }
}
.home-data-text h2 + p {
  margin: 30px auto 0;
}

@media (min-width: 1200px) {
  .home-data-text h2 + p {
    margin: 60px 0 0;
  }
}
.home-data-text h2 span {
  font-weight: 700;
  background: -webkit-gradient(linear, right top, left top, color-stop(18.85%, #FF0A71), color-stop(59.42%, #AD3DF9), color-stop(96.2%, #319CFF));
  background: -o-linear-gradient(right, #FF0A71 18.85%, #AD3DF9 59.42%, #319CFF 96.2%);
  background: linear-gradient(270deg, #FF0A71 18.85%, #AD3DF9 59.42%, #319CFF 96.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.home-data-text p {
  max-width: 526px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #E8E9EB;
}

@media (min-width: 1200px) {
  .home-data-text p {
    max-width: 100%;
    margin: 0;
    text-align: left;
  }
}
.home-data-text p + p {
  margin: 22px auto 0;
}

@media (min-width: 1200px) {
  .home-data-text p + p {
    margin: 22px 0 0;
  }
}
.home-data-image {
  position: relative;
  width: 100%;
  height: 680px;
}

@media (min-width: 768px) {
  .home-data-image {
    height: 900px;
  }
}
@media (min-width: 1200px) {
  .home-data-image {
    position: initial;
    height: auto;
  }
}
.home-data-image-blue {
  position: absolute;
  top: 200px;
  left: calc(50% - 170px);
  width: 180px;
}

@media (min-width: 768px) {
  .home-data-image-blue {
    top: 280px;
    left: calc(50% - 210px);
    width: 232px;
  }
}
@media (min-width: 1200px) {
  .home-data-image-blue {
    top: 0;
    left: calc(50% + 210px);
  }
}
.home-data-image-blue h3 {
  margin: 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .home-data-image-blue h3 {
    font-size: 24px;
  }
}
.home-data-image-blue h3 + p {
  margin: 10px 0 0;
}

@media (min-width: 768px) {
  .home-data-image-blue h3 + p {
    margin: 15px 0 0;
  }
}
.home-data-image-blue p {
  margin: 0;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .home-data-image-blue p {
    font-size: 16px;
  }
}
.home-data-image-blue img {
  width: 48px;
  margin: 30px 0 0 60px;
}

@media (min-width: 768px) {
  .home-data-image-blue img {
    width: 64px;
  }
}
.home-data-image-pink {
  position: absolute;
  top: 400px;
  left: calc(50% - 20px);
  width: 180px;
}

@media (min-width: 768px) {
  .home-data-image-pink {
    top: 540px;
    left: calc(50% - 20px);
    width: 200px;
  }
}
@media (min-width: 1200px) {
  .home-data-image-pink {
    top: 240px;
    left: calc(50% + 380px);
  }
}
.home-data-image-pink h3 {
  margin: 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .home-data-image-pink h3 {
    font-size: 24px;
  }
}
.home-data-image-pink h3 + p {
  margin: 10px 0 0;
}

@media (min-width: 768px) {
  .home-data-image-pink h3 + p {
    margin: 15px 0 0;
  }
}
.home-data-image-pink p {
  margin: 0;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .home-data-image-pink p {
    font-size: 16px;
  }
}
.home-story-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 50px 0 0;
}

@media (min-width: 576px) {
  .home-story-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .home-story-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .home-story-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.home-story-card {
  padding: 40px 20px 50px;
  text-align: center;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.home-story-card.story-blue {
  border: 2px solid #1F4AB5;
}

.home-story-card.story-violet {
  border: 2px solid #A048FA;
}

.home-story-card.story-pink {
  border: 2px solid #F50369;
}

.home-story-card.story-white {
  border: 2px solid #FFFFFF;
}

.home-story-card h3 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.home-story-card h3 + p {
  margin: 34px 0 0;
}

.home-story-card p {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #E8E9EB;
}

.home-story-card p + p {
  margin: 18px 0 0;
}

.home-story-card p + .btn {
  margin: 50px 0 0;
}

.home-story-card p strong {
  color: #FFFFFF;
}

.home-story-footer {
  margin: 50px 0 0;
  text-align: center;
}

.home-story-footer p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.home-story-footer p + .btn {
  margin-top: 30px;
}

.mail-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

.mail-text img {
  display: block;
  max-width: 200px;
  margin: 0;
}

@media (min-width: 576px) {
  .mail-text img {
    max-width: 320px;
  }
}
.mail-text h3 {
  width: 100%;
  max-width: 480px;
  margin: 40px 0 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: #AC8D3E;
}

@media (min-width: 576px) {
  .mail-text h3 {
    margin: 50px 0 0;
    font-size: 32px;
  }
}
.mail-text h4 {
  width: 100%;
  margin: 20px 0 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .mail-text h4 {
    font-size: 28px;
  }
}
.mail-text p {
  width: 100%;
  max-width: 700px;
  margin: 20px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #929292;
}

@media (min-width: 576px) {
  .mail-text p {
    font-size: 20px;
  }
}
.mail-form {
  margin: 30px 0 0;
}

@media (min-width: 576px) {
  .mail-form {
    margin: 40px 0 0;
  }
}
.mail-form .form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 480px;
  min-height: 75px;
  background-color: transparent;
}

@media (min-width: 576px) {
  .mail-form .form-wrapper {
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 100px;
  }
}
.mail-form .form-wrapper form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 15px;
}

@media (min-width: 576px) {
  .mail-form .form-wrapper form {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    grid-gap: 0;
  }
}
.mail-form .form-wrapper form .hs_email {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .mail-form .form-wrapper form .hs_email {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 140px);
    flex: 0 0 calc(100% - 140px);
    width: calc(100% - 140px);
  }
}
.mail-form .form-wrapper form .hs_email > label {
  display: none;
}

.mail-form .form-wrapper form .hs_email input,
.mail-form .form-wrapper form .hs_email input:focus,
.mail-form .form-wrapper form .hs_email input:active {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 16px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  background-color: #FFFFFF !important;
  border: 0;
  border-radius: 100px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mail-form .form-wrapper form .hs_submit {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .mail-form .form-wrapper form .hs_submit {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 140px;
    flex: 0 0 140px;
    width: 140px;
  }
}
.mail-form .form-wrapper form .hs_submit input {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.mail-form .form-wrapper form .hs-error-msgs {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (min-width: 576px) {
  .mail-form .form-wrapper form .hs-error-msgs {
    position: absolute;
    top: calc(100% + 10px);
    left: 40px;
  }
}
.mail-form .form-wrapper form .hs-error-msgs .hs-error-msg {
  display: block;
  color: red;
}

.mail-form .form-wrapper form .hs_error_rollup {
  display: none;
}

.thanks-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

.thanks-text img {
  display: block;
  max-width: 200px;
  margin: 0;
}

@media (min-width: 576px) {
  .thanks-text img {
    max-width: 320px;
  }
}
.thanks-text h3 {
  margin: 20px 0 0;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.25;
  color: #FFFFFF;
}

@media (min-width: 576px) {
  .thanks-text h3 {
    font-size: 56px;
    line-height: 1.25;
  }
}
.thanks-text p {
  margin: 5px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  color: #929292;
}

@media (min-width: 576px) {
  .thanks-text p {
    font-size: 24px;
  }
}
.thanks-icon {
  margin: 60px 0 0;
}

@media (min-width: 576px) {
  .thanks-icon {
    margin: 120px 0 0;
  }
}
.thanks-icon img {
  display: block;
  width: 40px;
}

@media (min-width: 576px) {
  .thanks-icon img {
    width: 80px;
  }
}
.thanks-button {
  margin: 200px 0 0;
}

@media (min-width: 576px) {
  .thanks-button {
    margin: 40px 0 0;
  }
}
.sadface-filled {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #F50369;
  border: 2px solid #8A044E;
  -webkit-box-shadow: 0px 4px 30px rgba(216, 21, 129, 0.5);
  box-shadow: 0px 4px 30px rgba(216, 21, 129, 0.5);
  border-radius: 5px;
}

@media (min-width: 576px) {
  .sadface-filled {
    width: 30px;
    height: 30px;
  }
}
.sadface-filled img {
  position: absolute;
  z-index: 1;
  top: -22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 64px;
}

@media (min-width: 576px) {
  .sadface-filled img {
    top: -40px;
    width: 98px;
  }
}
.sadface-empty {
  width: 24px;
  height: 24px;
  border: 2px solid #FFFFFF;
  -webkit-filter: drop-shadow(0px 4px 30px rgba(255, 255, 255, 0.5));
  filter: drop-shadow(0px 4px 30px rgba(255, 255, 255, 0.5));
  border-radius: 5px;
}

@media (min-width: 576px) {
  .sadface-empty {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }
}
@-webkit-keyframes homeHeroPanel4 {
  0% {
    top: -20px;
    opacity: 0;
  }
  65% {
    top: -20px;
    opacity: 0;
  }
  70% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@keyframes homeHeroPanel4 {
  0% {
    top: -20px;
    opacity: 0;
  }
  65% {
    top: -20px;
    opacity: 0;
  }
  70% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@-webkit-keyframes homeHeroPanel3 {
  0% {
    top: -20px;
    opacity: 0;
  }
  45% {
    top: -20px;
    opacity: 0;
  }
  50% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@keyframes homeHeroPanel3 {
  0% {
    top: -20px;
    opacity: 0;
  }
  45% {
    top: -20px;
    opacity: 0;
  }
  50% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@-webkit-keyframes homeHeroPanel2 {
  0% {
    top: -20px;
    opacity: 0;
  }
  25% {
    top: -20px;
    opacity: 0;
  }
  30% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@keyframes homeHeroPanel2 {
  0% {
    top: -20px;
    opacity: 0;
  }
  25% {
    top: -20px;
    opacity: 0;
  }
  30% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@-webkit-keyframes homeHeroPanel1 {
  0% {
    top: -20px;
    opacity: 0;
  }
  5% {
    top: -20px;
    opacity: 0;
  }
  10% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@keyframes homeHeroPanel1 {
  0% {
    top: -20px;
    opacity: 0;
  }
  5% {
    top: -20px;
    opacity: 0;
  }
  10% {
    top: 0;
    opacity: 1;
  }
  95% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
.pricing-wrapper {
  border: 1px solid #2763FF;
  border-radius: 16px;
}

.pricing-content {
  margin: 50px 0 0;
}

.pricing-period {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 12px;
}

.pricing-period p {
  cursor: pointer;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}

.pricing-period-toggle {
  cursor: pointer;
  position: relative;
  width: 68px;
  height: 24px;
  background-color: #2B313B;
  border-radius: 6px;
}

.pricing-period-toggle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-image: url('../../assets/icons/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #2763FF;
  border-radius: 6px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.pricing-period-toggle.annualy::before {
  left: 50%;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.pricing-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin: 24px 0 0;
}

@media (min-width: 768px) {
  .pricing-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .pricing-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.pricing-card {
  position: relative;
  padding: 24px;
  border-radius: 16px;
}

.pricing-card.blue {
  border: 2px solid #1F4AB5;
}

.pricing-card.violet {
  border: 2px solid #A048FA;
}

.pricing-card.pink {
  border: 2px solid #F50369;
}

.pricing-card.popular .most-popular {
  position: absolute;
  top: -2px;
  right: -2px;
  padding: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-radius: 0 16px 0 8px;
}

.pricing-card.blue.popular .most-popular {
  background-color: #1F4AB5;
}
.pricing-card.violet.popular .most-popular {
  background-color: #A048FA;
}
.pricing-card.pink.popular .most-popular {
  background-color: #F50369;
}

/*.pricing-card > h2 {
  margin: 24px 0 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}*/

.pricing-card > h2 {
  margin: 24px 0 0;
  font-weight: 400;
  font-size: 42px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.pricing-card > h2 span {
  font-weight: 700;
}

.pricing-card > h2.custom {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* margin: 80px 0 89px; */
  margin: 17px 0px -8px 0px;
}

.pricing-card > h2.hide {
  display: none;
}

.pricing-card > h3 {
  margin: 24px 0 0;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.pricing-card > p {
  margin: 12px 0 0;
  padding: 0 12px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #BABEC7;
}

.pricing-card > p.white {
  color: #FFFFFF;
}

.pricing-card > p span.bold {
  font-weight: 700;
}

.pricing-card > .btn {
  margin: 48px 0 0;
}

.pricing-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 88px;
  height: 36px;
  margin: 0 auto;
  border-radius: 8px;
}

.pricing-image.blue {
  background-color: #1F4AB5;
}

.pricing-image.violet {
  background-color: #A048FA;
}

.pricing-image.pink {
  background-color: #F50369;
}

.pricing-slider-limit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-slider-limit p {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}

.pricing-slider-wrapper {
  margin: 12px 0 0;
}

.pricing-feature {
  margin: 24px 0 0;
  padding: 24px;
  background-color: #141A25;
  border-radius: 8px;
}

.pricing-feature > h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}

.pricing-feature > h4 + .pricing-feature-list {
  margin: 24px 0 0;
}

.pricing-feature-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pricing-feature-list > img {
  width: 14px;
  margin: 6px 0 0;
}

.pricing-feature-list > p {
  width: calc(100% - 14px);
  margin: 0;
  padding: 0 0 0 12px;
  color: #FFFFFF;
  font-size: 14px;
}

.pricing-feature-list + .pricing-feature-list {
  margin: 12px 0 0;
}

.pricing-testimonial {
  padding: 24px;
}

@media (min-width: 992px) {
  .pricing-testimonial {
    padding: 24px 24px 134px;
  }
}
.pricing-testimonial h2 {
  max-width: 290px;
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.pricing-testimonial-wrapper {
  position: relative;
  margin: 24px 0 0;
  padding: 0;
}

@media (min-width: 992px) {
  .pricing-testimonial-wrapper {
    margin: 72px 0 0;
    padding: 0 0 0 370px;
  }
}
.pricing-testimonial-wrapper > img {
  display: none;
  position: absolute;
  top: -124px;
  left: 62px;
  width: 440px;
}

@media (min-width: 992px) {
  .pricing-testimonial-wrapper > img {
    display: block;
  }
}
.pricing-faq-wrapper {
  margin: 40px 0 0;
}

.pricing-faq-list > h3 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #F9C00C;
}

.pricing-faq-list > h3 + p {
  margin: 12px 0 0;
}

.pricing-faq-list > p {
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}

.pricing-faq-list + .pricing-faq-list {
  margin: 24px 0 0;
  padding: 18px 0 0;
  border-top: 1px solid #2F3542;
}

.pricing-faq-accordion {
  max-width: 900px;
  margin: 0 auto;
}

.pricing-faq-accordion .accordion {
  background-color: #12171F;
  border-radius: 8px;
}

.pricing-faq-accordion .accordion + .accordion {
  margin: 15px 0 0;
}

.pricing-power-wrapper {
  margin: 80px 0 0;
}

.pricing-power-wrapper .section-title p {
  max-width: 926px !important;
  margin: 20px auto 0 !important;
}

.testimonial-nav {
  position: absolute;
  bottom: -28px;
  left: 445px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 12px;
}

.testimonial-nav .prev,
.testimonial-nav .next {
  cursor: pointer;
}

.testimonial-nav img {
  height: 12px;
}

.testimonial-carousel {
  position: relative;
}

.testimonial-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonial-image {
  width: 60px;
}

.testimonial-image img {
  width: 60px;
  height: 60px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
}

.testimonial-text {
  width: calc(100% - 60px);
  padding: 0 0 0 15px;
}

.testimonial-text h3 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #FFFFFF;
}

.testimonial-text h4 {
  margin: 6px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #BABEC7;
}

.testimonial-text p {
  margin: 12px 0 0;
  font-size: 16px;
  line-height: 21px;
  color: White;
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 24px;
  padding: 0;
  margin: 0;
  background-color: #141A25;
  border-radius: 12px;
  outline: none;
  overflow: hidden;
}

input[type=range]::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: #2763FF;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  -webkit-box-shadow: -1012px 0 0 1000px #2763FF;
  box-shadow: -1012px 0 0 1000px #2763FF;
}

input[type=range]::-moz-range-thumb {
  cursor: pointer;
  width: 22px;
  height: 22px;
  background-color: #2763FF;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
}

input[type=range]::-moz-range-progress {
  height: 24px;
  background-color: #2763FF;
}

/* Vertical Rotator */
.vertical-rotator {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: -15px;
  font-size: 40px;
  padding: 10px 0px;
}

.vertical-rotator span {
  position: relative;
  font-size: 52px;
}

/* BLOG POST */

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 10000 !important;
  transition: opacity 200ms ease-in-out;
}


.ReactModal__Overlay.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.blog-post .header-logo img {
  border-radius: 0px !important;
  box-shadow: none;
  height: 20px;
  margin-top: 1em !important;
}

.blog-post img {
  width: 100%;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  border-radius: 16px;
  overflow: hidden;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: 0 7px 28px rgba(0, 0, 0, 0.2);
}

.blog-post p {
  padding-top: 1em;
  font-size: 1.1em !important;
  max-width: 720px;
  line-height: 1.5em;
  margin: auto !important;
  text-rendering: optimizeLegibility;
  font-family: 'Lora', serif;
}

.blog-post a {
  color:rgb(245, 3, 105) !important;
  font-weight: 500;
}

.blog-post a:hover {
  text-decoration: underline !important;
}


.blog-post .has-small-font-size strong,
.blog-post .has-medium-font-size strong,
.blog-post .has-large-font-size strong {
  padding-top: 3em !important;
  font-size: 1.3em !important;
  font-family: 'inter', sans-serif;
}

.blog-post .wp-embedded-content {
  margin: 20px auto;
}

.blog-post .wp-embedded-content > .h-screen {
  height: auto !important;
}

.blog-post .wp-block-embed__wrapper, .blog-post .wp-block-audio audio {
  margin: 20px auto;
  width: 100%;
}

@keyframes float {
  0% {
      transform: translatey(0px);
  }
  20% {
      transform: translatey(-30px);
  }
  100% {
      transform: translatey(0px);
  }
}

.float {
  animation: float 2s ease-in-out infinite;
}

@media (max-width: 767px) {
  .ReactModal__Content.ReactModal__Content--after-open{
    inset: 0px !important;
  }
}
